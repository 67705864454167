module.exports = {
  sassVariables: {
    primary: '#f4b937',
    secondary: '#821b1e',
    'navbar-back-rotation': '40deg',
    'navbar-link-rotation': '-100deg',
    'login-background': "url('/images/bnb-back.jpg')",
    'secondary-background': "''",
  },
  images: {
    loginLogo: '/images/tm-bad-news-bears.png',
    iconLogo: '/images/icon-logo-white.png',
    fullLogo: '/images/tm-bad-news-bears.png',
    overviewLogo: '/images/tm-bad-news-bears.png',
    faviconLogo: '/images/icon-logo.png',
    poweredByAdvocate: '/images/powered-by-advocate.png',
  },
}

